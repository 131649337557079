import React from 'react';
import { Layer } from 'react-map-gl';
import { hoverExpr } from './constants';
import { useMapContext } from '../MapContext';
import { SourcesLayers } from '../SourcesLayers';

export const S06_tanavatuubid: React.FC = () => {
	const {
		activeLayers,
		mapData: { sources },
		mapId,
	} = useMapContext();

	const layers = {
		'S06-tanavatuubid-local': (
			<Layer
				key={'S06-tanavatuubid-local'}
				id={'S06-tanavatuubid-local'}
				type={'line'}
				source={'S06-tanavatuubid-local'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#F79DA3', '#ABE7AE'],
					'line-opacity': activeLayers.includes('Kohalik') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 6],
						],
					},
				}}
			/>
		),

		'S06-tanavatuubid-town_square': (
			<Layer
				key={'S06-tanavatuubid-town_square'}
				id={'S06-tanavatuubid-town_square'}
				type={'line'}
				source={'S06-tanavatuubid-town_square'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#33AB61'],
					'line-opacity': activeLayers.includes('Kohalik') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 12],
						],
					},
				}}
			/>
		),

		'S06-tanavatuubid-city_place': (
			<Layer
				key={'S06-tanavatuubid-city_place'}
				id={'S06-tanavatuubid-city_place'}
				type={'line'}
				source={'S06-tanavatuubid-city_place'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#00752C'],
					'line-opacity': activeLayers.includes('Kohalik') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 12],
						],
					},
				}}
			/>
		),

		'S06-tanavatuubid-city_street': (
			<Layer
				key={'S06-tanavatuubid-city_street'}
				id={'S06-tanavatuubid-city_street'}
				type={'line'}
				source={'S06-tanavatuubid-city_street'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#6B2CC4'],
					'line-opacity': activeLayers.includes('Piirkondlik') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 12],
						],
					},
				}}
			/>
		),
		'S06-tanavatuubid-high_street': (
			<Layer
				key={'S06-tanavatuubid-high_street'}
				id={'S06-tanavatuubid-high_street'}
				type={'line'}
				source={'S06-tanavatuubid-high_street'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#B17FF9'],
					'line-opacity': activeLayers.includes('Piirkondlik') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 12],
						],
					},
				}}
			/>
		),

		'S06-tanavatuubid-connector': (
			<Layer
				key={'S06-tanavatuubid-connector'}
				id={'S06-tanavatuubid-connector'}
				type={'line'}
				source={'S06-tanavatuubid-connector'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#C9ADF1'],
					'line-opacity': activeLayers.includes('Piirkondlik') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 12],
						],
					},
				}}
			/>
		),

		'S06-tanavatuubid-city_hub': (
			<Layer
				key={'S06-tanavatuubid-city_hub'}
				id={'S06-tanavatuubid-city_hub'}
				type={'line'}
				source={'S06-tanavatuubid-city_hub'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#D42D39'],
					'line-opacity': activeLayers.includes('Ülelinnaline') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 12],
						],
					},
				}}
			/>
		),

		'S06-tanavatuubid-high_road': (
			<Layer
				key={'S06-tanavatuubid-high_road'}
				id={'S06-tanavatuubid-high_road'}
				type={'line'}
				source={'S06-tanavatuubid-high_road'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#F1823F'],
					'line-opacity': activeLayers.includes('Ülelinnaline') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 12],
						],
					},
				}}
			/>
		),

		'S06-tanavatuubid-core_road': (
			<Layer
				key={'S06-tanavatuubid-core_road'}
				id={'S06-tanavatuubid-core_road'}
				type={'line'}
				source={'S06-tanavatuubid-core_road'}
				layout={{ 'line-cap': 'round' }}
				paint={{
					'line-color': ['case', hoverExpr, '#F79DA3', '#F3D03E'],
					'line-opacity': activeLayers.includes('Ülelinnaline') ? 1 : 0,
					'line-opacity-transition': {
						duration: 600,
						delay: 0,
					},
					'line-width': {
						base: 1,
						stops: [
							[10, 1],
							[12, 1],
							[16, 12],
						],
					},
				}}
			/>
		),
	};

	if (!sources) return null;

	return <SourcesLayers mapId={mapId} sources={sources} layers={layers} />;
};
