import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { HoverFeature } from '../../../types/maps';
import { gridSourceLayerName } from '../layers/constants';
import { useMapContext } from '../MapContext';
import { MapEvent } from 'react-map-gl';

export function useMapPopup() {
	const {
		setPopup,
		setHoverFeatures,
		mapData: { data },
	} = useMapContext();

	return useCallback(
		(ev: MapEvent) => {
			const topSource = ev.features?.find((feature) => !!feature.source && !isEmpty(feature.properties));

			if (!topSource) {
				setPopup(null);
				setHoverFeatures([]);
				return;
			}

			const sourceData = { ...topSource };
			const objData =
				topSource.source === 'composite' && topSource.sourceLayer === gridSourceLayerName
					? data?.[getGridItemId(ev)]
					: topSource.properties;

			if (!objData) {
				return null;
			}

			sourceData.source = objData;

			const hoverFeatures = [topSource as unknown as HoverFeature];

			setHoverFeatures(hoverFeatures);
			// @ts-ignore
			setPopup({ position: ev.point, sourceData });
		},
		[data]
	);
}

function getGridItemId(ev: MapEvent): string {
	//@ts-ignore
	const gridFeature = ev.features?.find((f) => f.source === 'composite' || f.sourceLayer === gridSourceLayerName);
	return gridFeature?.properties?.GRD_INSPIR || '';
}
