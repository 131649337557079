import { useMapContext } from './MapContext';
import styled from 'styled-components';
import { renderString } from 'nunjucks';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { screenSize } from '../../styles/ScreenSize';
import LineIcon from '../../images/line_fat.svg';

const PopoverWrapper = styled.div`
	background-color: white;
	box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.1);
	border-radius: 2px;

	display: flex;
	flex-direction: row;
	align-items: center;

	padding: 3px 7px;
	max-width: 320px;

	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.02em;

	color: #0072ce;

	b {
		display: inline-block;
		width: 100%;

		:not(:first-of-type) {
			margin-top: 5px;
		}
	}

	${screenSize.sm} {
		max-width: 256px;
	}
`;

const PopupIcon = styled(LineIcon)<{ color?: string }>`
	width: 18px;
	height: 18px;
	margin: 4px 6px 4px 0;

	path {
		fill: ${(p) => p.color?.toString()};
	}
`;

export const MapPopover = () => {
	const [reference, setReference] = useState<HTMLDivElement | null>(null);
	const {
		popUp,
		mapData: { meta },
		activeLayers,
	} = useMapContext();

	const { refs, floatingStyles, update } = useFloating({
		elements: {
			reference,
		},
		whileElementsMounted: autoUpdate,
		middleware: [shift(), offset({ mainAxis: 16 }), flip()],
		placement: 'top',
	});

	useEffect(() => {
		if (popUp?.position) {
			update();
		}
	}, [popUp]);

	const popupColor = useMemo(
		() =>
			renderString(meta?.popup?.color || '', {
				...meta?.popup?.data,
				sources: popUp?.sourceData,
				activeLayers,
			}),
		[meta?.popup?.color, popUp?.sourceData, activeLayers]
	);

	const popupMessage = useMemo(() => {
		// Messy solution – current data structure is not optimal for filtering by active layers

		if (meta?.name === 'S00-linnaosade-areng') {
			if (!activeLayers.find((layerSlug) => popUp?.sourceData?.layer?.id.includes(layerSlug))) return null;
		}

		if (popUp?.sourceData?.source.tyyp_et && !activeLayers.includes(popUp?.sourceData?.source.tyyp_et)) return null;

		return renderString(meta?.popup?.template || '', {
			...meta?.popup?.data,
			sources: popUp?.sourceData,
			activeLayers,
		});
	}, [popUp, activeLayers, meta]);

	if (!popUp?.position) {
		return null;
	}

	if (!popupMessage) return null;

	return (
		<>
			<div style={{ position: 'absolute', left: popUp?.position[0], top: popUp.position[1] }} ref={setReference} />
			<PopoverWrapper ref={refs.setFloating} style={floatingStyles}>
				{!!popupColor && <PopupIcon color={popupColor} />}
				<div dangerouslySetInnerHTML={{ __html: popupMessage }} />
			</PopoverWrapper>
		</>
	);
};
