import * as React from 'react';
import { CSSProperties, FC, ReactElement, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import 'mapbox-gl/dist/mapbox-gl.css';

import { FullscreenControl, MapNavigationControl, MapScaleControl } from './MapControls';
import { ExtraState, InteractiveMap, MapEvent, ViewState } from 'react-map-gl';
import { clamp } from 'lodash';

import { MapLegend } from './MapLegend';
import { MapTitle } from './MapTitle';
import { MapPopover } from './Popup';
import { MapContextProvider, useMapContext } from './MapContext';
import './NunjucksConfig';
import { MapLayers } from './MapLayers';
import { useMapPopup } from './hooks/useMapPopup';
import { gridSourceLayerName } from './layers/constants';

//<editor-fold desc="Styled Components">
interface Wrapper {
	fullscreen?: boolean;
}

const Wrapper = styled.figure<Wrapper>`
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row;
	position: relative;

	${(p) =>
		p.fullscreen &&
		css`
			position: fixed;
			width: 100vw;
			right: 0;
			top: 0;
			bottom: 0;
		`}
	.mapboxgl-ctrl-logo {
		display: none;
	}
`;

const Caption = styled.figcaption`
	height: 0;
	padding: 0;
	position: absolute;
`;
//</editor-fold>

const defaultMapProps = {
	latitude: 59.437,
	longitude: 24.746,
	zoom: 10.5,
	maxZoom: 14,
	minZoom: 10,
};

interface Map {
	className?: string;
	style?: CSSProperties;
	id: string;
	enableFullscreen?: boolean;
	sidebar?: ReactElement;
}

export const Map: FC<Map> = (mapProps) => {
	return (
		<MapContextProvider mapId={mapProps.id}>
			<RenderMap {...mapProps} />
		</MapContextProvider>
	);
};

export const RenderMap: React.FC<Map> = ({ className, style, enableFullscreen, children, sidebar }) => {
	const {
		mapRef,
		mapData: { sources, meta },
		setActiveLayers,
	} = useMapContext();

	const [viewState, setViewState] = useState<ViewState>();
	const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
	const initialMapProps = useMemo(() => ({ ...defaultMapProps, ...meta?.viewState }), [meta]);

	useEffect(() => {
		if (!meta?.legend) return;
		const activeLayers = meta.legend.flatMap((legend) => legend.filter((item) => item.slug).map((item) => item.slug));
		setActiveLayers(activeLayers.filter((layer) => layer !== undefined));
	}, [sources]);

	const interactiveLayerIds = useMemo(() => {
		const sourcesArray = sources?.map((s) => s.name) || [];
		if (meta?.useGrid) sourcesArray.push('grid');

		return sourcesArray;
	}, [sources, meta]);

	// Missing types
	const handleViewportChange = (info: any) => {
		if (meta?.maxBounds) {
			const [lngMax, latMax] = meta.maxBounds[0];
			const [lngMin, latMin] = meta.maxBounds[1];

			info.viewState.longitude = clamp(lngMin, lngMax, info.viewState.longitude);
			info.viewState.latitude = clamp(latMin, latMax, info.viewState.latitude);
		}

		setViewState(info.viewState);
	};

	const handleHover = useMapPopup();

	function handleLayerChange(layers: string[]) {
		setActiveLayers(layers);
	}

	function handleClick(ev: MapEvent) {
		ev.features?.forEach((feature) => {
			const interactiveLayer = meta?.interactionLayers?.find((il) => il.id === feature.source);

			if (interactiveLayer && feature.properties.hasOwnProperty(interactiveLayer.property)) {
				setActiveLayers([feature.properties[interactiveLayer.property]]);
			}
		});
	}

	function toggleFullscreen() {
		setIsFullscreen((prevValue) => !prevValue);
	}

	return (
		<Wrapper className={className} style={style} fullscreen={isFullscreen} aria-labelledby={meta?.name}>
			<Caption>{meta?.caption}</Caption>
			<InteractiveMap
				ref={mapRef}
				mapboxApiAccessToken={process.env.MAPBOX_API_TOKEN}
				{...initialMapProps}
				viewState={viewState}
				mapStyle="mapbox://styles/tln2030/ck6ar6wsv1uay1imrwxbn4pqo"
				width="100%"
				height="100%"
				onViewStateChange={handleViewportChange}
				onClick={handleClick}
				onHover={handleHover}
				interactiveLayerIds={interactiveLayerIds}
				dragRotate={false}
				attributionControl={true}
				disableTokenWarning={true}
				reuseMaps={false}
				preventStyleDiffing={false}
				getCursor={getCursor}
				clickRadius={meta?.clickRadius || 0}
			>
				<MapLayers />

				{enableFullscreen && <FullscreenControl onClick={toggleFullscreen} />}
				{children}

				<MapScaleControl />
				<MapNavigationControl />
				<MapPopover />
			</InteractiveMap>
			<MapTitle meta={meta} />
			<MapLegend legend={meta?.legend} onLayerChange={handleLayerChange} />
			{sidebar}
		</Wrapper>
	);
};

function getCursor({ isDragging }: ExtraState) {
	return isDragging ? 'move' : 'default';
}
